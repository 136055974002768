import React from "react";
import { Content } from "patterns/Content";
import { ContentBlock } from "patterns/ContentBlock";
import { MastheadNewsletter } from "patterns/MastheadNewsletter";
import { Shell } from "patterns/Shell";

import "../../scss/main.scss";

function NewsletterIssue12({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            pageDescription="New business plans, paid products on the horizon and more"
            pageSocialImage=""
            pageTitle="Issue #12"
            pathname={pathname}
        >
            <MastheadNewsletter
                authorImage={{
                    alt: "",
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: "https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format"
                }}
                authorName="Rob Simpson"
                title="Issue #12"
            />
            <div className="container-large">
                <Content
                    content={[
                        {spans: [], text: "I’ve not had the motivation to work on any side projects for the past few months. I’ve done bits here and there but nothing significant.", type: "paragraph"},
                        {spans: [{
                            start: 14,
                            end: 34,
                            type: "hyperlink",
                            data: {
                                link_type: "Document",
                                uid: "newsletter/issue-11",
                            },
                        }], text: "I did write a newsletter for March but I never got around to sending it so if you want to give that a read you can check it out on my website.", type: "paragraph"},
                        {spans: [], text: "Fortunately, I’ve got some excitement and time back to work on these things again, so here goes.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🗓 Looking back at May"
                    content={[
                        {spans: [], text: "👷‍♂️ Work", type: "heading4"},
                        {spans: [], text: "May was a busy month, wrapping up Etcho and getting it live. This meant I had a backlog of work from other clients (including the first 2 clients I worked with when I started freelancing).", type: "paragraph"},
                        {spans: [], text: "I got to do some design work for the first time in a while which was nice, some of which was to make some updates to a design project I first started in December and is nearing completion from a development perspective.", type: "paragraph"},
                        {spans: [], text: "Development wise I’ve been challenged more this month than usual. I’ve been diving into the world of 3d – which was a big learning curve, but I managed to create something with interactivity that is production worthy.", type: "paragraph"},
                        {spans: [], text: "I’ve also been diving into page transitions with Framer motion (morphing one element to another) – this is my first time doing this properly with React and it brings more challenges than I remember with regular old Javascript and HTML.", type: "paragraph"},
                        {spans: [], text: "🛺 Improving systems", type: "heading4"},
                        {spans: [], text: "I had the urge to start developing more systems in my business and improve client management.", type: "paragraph"},
                        {spans: [], text: "I figured I’d check out Teamwork and Asana to see what they’re like, but they seem far better for teams and my current Notion setup does a lot more, so I spent some time improving my current Notion setup (still more improvements to be made, which I’ll release as a product once finished).", type: "paragraph"},
                        {spans: [], text: "Figma recently rolled out some new features and so I spent some time updating my Figma boilerplate – which had become a little outdated. I use this boilerplate for new projects, which allows me to create hi-fi wireframes and prototypes quickly which are then turned into the final product.", type: "paragraph"},
                        {spans: [], text: "🗺 Business plans", type: "heading4"},
                        {spans: [], text: "For quite some time I’ve been thinking about niching down my business further.", type: "paragraph"},
                        {spans: [], text: "To date, I’ve been focused on Jamstack websites, but I really want to help environmentally conscious companies create low-carbon websites and so at a high level this is what am I working towards.", type: "paragraph"},
                        {spans: [], text: "It’ll mean writing all new content for my website and updating design elements for the new content so stay tuned for updates on this.", type: "paragraph"},
                        {spans: [], text: "🩳 Creating paid products", type: "heading4"},
                        {spans: [], text: "I’ve been thinking about creating paid products for some time, but I’ve always talked myself out of it – no one will find the products useful, it’s all common knowledge etc.", type: "paragraph"},
                        {spans: [], text: "But I know there is value to be gained from everything, whilst I’m going through this process of improving my systems, I’m going to turn the information into products that others can use.", type: "paragraph"},
                        {spans: [], text: "I’ll start with my Figma boilerplate followed by my Notion system.", type: "paragraph"},
                        {spans: [], text: "These will consist of files you can use yourself along with the ideas and thought process behind them – so there should be something useful inside.", type: "paragraph"},
                        {spans: [], text: "👨‍🍳 Stryve updates", type: "heading4"},
                        {spans: [], text: "I’ve made a few updates to Stryve since my last newsletter, but the most interesting one which I’d be interested to hear your thoughts on, are audio versions of blog posts.", type: "paragraph"},
                        {spans: [{
                            start: 84,
                            end: 92,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://blogcast.host/",
                            },
                        }], text: "I’ve added it to one of my posts, but this audio version has been generated through blogcast. ", type: "paragraph"},
                        {spans: [], text: "Would you listen to a post if it was available even if it is clearly generated?", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 May goals"
                    checkList={[
                        {complete: true, text: "🥾 Launch Etcho project"},
                        {complete: true, text: "🏁 Finish “bitty” projects"},
                        {complete: false, text: "🏋️ Start working out again"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="🗓 Looking ahead to June"
                    content={[
                        {spans: [], text: "June is booked up with a couple of holidays and time off work. As it stands I’ll mostly be working Mon-Wed with the last week of June off on holiday.", type: "paragraph"},
                        {spans: [], text: "So I won’t have a lot of time for client work or personal projects for that matter, but I’m hoping the optimisations with my workflow will make it possible to still launch new content next month.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 June goals"
                    checkList={[
                        {complete: false, text: "🩳 Launch my first paid product"},
                        {complete: false, text: "🛺 Improve client onboarding processes"},
                        {complete: false, text: "🔬 Research ways to make tasks more efficient"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="💡 Freelance tip of the month"
                    quote="Break lofty goals into smaller bite-sized tasks, this can make things far more approachable"
                />
                <ContentBlock
                    title="💰 Earnings for the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/f1525bc0-4a33-40d5-a4c8-f4234003f8a3_income-may-2022.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Income: £8.7k", type: "paragraph"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="📦 Product of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/e65a98a6-fa72-405e-a66d-24faacbbc2c6_vivobarefoot.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Vivobarefoot shoes", type: "heading4"},
                        {spans: [], text: "At the beginning of the year, I said I’d buy myself a pair of Vivobarefoot shoes if I’m still walking regularly by April. These shoes didn’t take as much adjustment as I expected, the first couple of days did feel weird – not having that heel I was so used to. But by the second week, I had pretty much gotten used to them. Hopefully the regular walking I do with these will strengthen my feet.", type: "paragraph"},
                    ]}
                    buttonText="View product"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.vivobarefoot.com/uk/primus-lite-iii-mens",
                    }}
                />
                <ContentBlock
                    title="📙 Book of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/1c1beaea-ae49-4c85-b9e0-01dacc32ae3c_vegan-propaganda.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "This is vegan propaganda", type: "heading4"},
                        {spans: [], text: "I didn’t know what to expect from this book. It opens with horrific stories of what happens in the meat industry – comparable to that of a gruesome horror movie. Animals aside, the impact that eating meat has on the environment, potential future pandemics and your health are all valid reasons to consider the switch to a more plant-based diet.", type: "paragraph"},
                    ]}
                    buttonText="View book"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.amazon.co.uk/This-Vegan-Propaganda-Other-Industry-ebook/dp/B09DKLPZH6/",
                    }}
                />
            </div>
        </Shell>
    );
}

export default NewsletterIssue12;
